import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "全ての働く人に知って欲しいプログラミング序論〜メドレー非エンジニア向け勉強会〜",
  "date": "2017-01-17T08:49:21.000Z",
  "slug": "entry/2017/01/17/174921",
  "tags": ["medley"],
  "hero": "./2017_01_17.png",
  "heroAlt": "programming"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、エンジニア・`}<a parentName="p" {...{
        "href": "https://www.target-darts.jp/shopdetail/000000000022/ct33/page1/recommend/"
      }}>{`プロダーツプレイヤー`}</a>{`の徐 聖博です（最近、介護施設の口コミサイト「`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`」から、オンライン病気事典「`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`MEDLEY`}</a>{`」のチームに異動しました）。`}</p>
    <p>{`メドレーでは、定期的にエンジニア向け勉強会はもちろんのこと、非エンジニア向けにも技術勉強会を開催しています。
`}<a parentName="p" {...{
        "href": "https://info.medley.jp/entry/2016/11/02/122944"
      }}>{`前回の非エンジニア向け Git 勉強会`}</a>{`に続き、今回は`}<strong parentName="p">{`非エンジニアのためのプログラミング序論`}</strong>{`というタイトルで、全社員の希望者を対象にプログラミング入門講座を行いました。`}</p>
    <iframe className="embed-card embed-blogcard" style={{
      "display": "block",
      "width": "100%",
      "height": "190px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="医師も Github にコミット！メドレーの目指す「全員で行う高速開発体制」について 〜非エンジニア向け勉強会(Git 編)〜 - MEDLEY オフィシャルブログ" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fdeveloper.medley.jp%2Fentry%2F2016%2F11%2F02%2F122944" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://developer.medley.jp/entry/2016/11/02/122944">developer.medley.jp</a></cite>
    <p>{`最終的に日常業務でもプログラミングを使い業務の効率化をするために、今回はプログラミングの入門編という位置づけで基礎的な内容について勉強しました。それをブログにまとめたので、ご興味ある方は是非最後まで読んでいただけると光栄です。`}</p>
    <h1>{`でも、プログラミングって難しいんでしょ？`}</h1>
    <p>{`プログラミングというと、「なんか難しそう」・「え、私無理」と思う方が多いのではないでしょうか。
興味がわいても、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`・`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/PHP"
      }}>{`PHP`}</a>{`・`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Java"
      }}>{`Java`}</a>{`・`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/C%B8%C0%B8%EC"
      }}>{`C 言語`}</a>{`･･･といった言語の多様さをみて「どれから勉強していいの？」と挫折してしまったという方もいるかもしれません。`}</p>
    <p>{`「プログラミング」というのは限られた概念の中で、あの手この手で人の意図した処理を行っているだけなので難しいことはありません。
今回は、そのいくつかの概念を理解してしまえば簡単だという意識を持ってもらうことを目標にしました。`}</p>
    <p>{`また、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D7%A5%ED%A5%B0%A5%E9%A5%DF%A5%F3%A5%B0%B8%C0%B8%EC"
      }}>{`プログラミング言語`}</a>{`も様々なものがありますがこちらも恐れることはありません。
様々な`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D7%A5%ED%A5%B0%A5%E9%A5%DF%A5%F3%A5%B0%B8%C0%B8%EC"
      }}>{`プログラミング言語`}</a>{`がありますが、これは「こんにちは」という日本語を、英語で「Hello」、中国語で「你好(ニーハオ)」、フランス語で「`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Bonjour"
      }}>{`Bonjour`}</a>{`(ボンジュール)」というように、"こんにちは"という概念を違う言葉に置き換えただけのものなのです。
さらには試験ではないので、ネットで`}<strong parentName="p">{`検索し放題`}</strong>{`です。
様々な言語はありますが、基本となる概念は一緒なことが多いので恐れることはありません。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20161122/20161122151851.png",
        "alt": null
      }}></img></p>
    <p>{`メドレーで使われいる様々な言語。エンジニアは各々詳しい言語はあれども、業務において一通り触ります。`}</p>
    <h1>{`勉強会の様子`}</h1>
    <p>{`というわけで、ともに働く弁護士や医師、デザイナー、広報などなど「プログラミング触れたことないよ」という非エンジニア向けに、プログラミングについて勉強会を開催しました。
「介護のほんねってどのように動いているのか」「どういうシステムで何をプログラミングしているのか」ということから説明をはじめました。`}</p>
    <p>{`そして、実際に`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D7%A5%ED%A5%B0%A5%E9%A5%DF%A5%F3%A5%B0%B8%C0%B8%EC"
      }}>{`プログラミング言語`}</a>{`「`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`」を使ってもらいながら`}</p>
    <ul>
      <li parentName="ul">{`変数と代入`}</li>
      <li parentName="ul">{`四則演算 + α`}</li>
      <li parentName="ul">{`繰り返し`}</li>
      <li parentName="ul">{`条件分岐`}</li>
      <li parentName="ul">{`関数`}</li>
    </ul>
    <p>{`という 5 つの基本的な概念を勉強しました。`}</p>
    <p>{`当日は「介護のほんねのこの部分はどんな関数で動いているの？」などの質問がどんどん飛んできたり「そうか、この動きはこういう意味だったのか！」というガッテンをいただくなど（予想以上に）盛り上がり、参加者がプログラミングを身近に感じる時間になったのではと思います。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20161212/20161212165359.jpg",
        "alt": null
      }}></img></p>
    <p>{`勉強会での様子`}</p>
    <p>{`勉強会で使われた資料`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`実際に開発現場では、開発者はサーバー構成を初めとした`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A2%A1%BC%A5%AD%A5%C6%A5%AF%A5%C1%A5%E3"
      }}>{`アーキテクチャ`}</a>{`の構成や、周辺サービスとの連携など様々なことを考えながら日々業務を行っています。
プログラミングはその中でも一つの業務であり、そのプログラミングの基本的な概念について今回は実際に`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`を使いながら学ぶ会としました。`}</p>
    <p>{`プログラミングは難しいと思われがちですが、元をたどれば"ほぼ"今回勉強した概念でカバーできると考えています。
実際、「プログラミングは難しいから」と食わず嫌いをしている人は多いと思います。
「私、文系だから」「黒い画面をみるだけで無理」など様々な言葉を聞きますが、`}<strong parentName="p">{`こうした「概念」だけ理解できれば特別恐れることはことはありません。`}</strong>{`
今回紹介した概念を理解することで、企画の際の作業工数見積がしやすくなったり、エンジニアからされる説明に対する理解が深まったりすると考えています。
また、エンジニアが日々何をやっているのかを理解する足がかりになればと思っています。`}</p>
    <p>{`今後は、今回学んだプログラミングを基礎として、もっと実務で役立つプログラミングなどについての勉強会を行っていく予定です。
「スクレイプして営業リストを作成する」・「ボットを作って定常的なリマインドをさせる」・「定期的に発生する単純作業をプログラムで一瞬で終わらせる」など業務に役立つことを紹介していきたいと思っています。
怠惰は`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D7%A5%ED%A5%B0%A5%E9%A5%DE%A1%BC"
      }}>{`プログラマー`}</a>{`としての最大の美徳と言われています。このエッセンスを全ての働く人に知って頂き、日々の業務の効率化に努めていただきたいなと思っています。
プログラミングに興味がある方、非エンジニアにレクチャーしていきたいと考えている方、ぜひ感想や「もっとこうしてみては」というご意見などを頂けると嬉しいです！`}</p>
    <p>{`※`}<a parentName="p" {...{
        "href": "https://www.facebook.com/medley.jp/"
      }}>{`メドレー公式 Facebook ページ`}</a>{`に「いいね！」していただけると、ブログの最新情報をフォローできます`}</p>
    <p>{`今後とも、よろしくお願いします！`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      